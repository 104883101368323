import 'jquery-backstretch';
$.backstretch("/img/KMG_Background_DH_nogrid.jpg");

let KMG = {};

import { Slider } from './components/kmg.flexslider';
KMG.Slider = new Slider();

import { Forms } from "./components/kmg.forms";
KMG.Forms = new Forms();

/***************************
(c) 2008 www.TUFaT.com
All Rights Reserved. Please
do not re-sell/re-distribute
this software.
***************************/
function formatNumberDec(num, places, comma) {
    var isNeg = 0,
        myDecFact = 1,
        myPlaces = 0,
        myZeros = "",
        decimal,
        integer,
        onum,
        fillZeroes,
        z,
        tmpnum = "",
        tmpinteger = "",
        y = 0,
        x,
        finNum;

    if (num < 0) {
        num = num * -1;
        isNeg = 1;
    }
    while (myPlaces < places) {
        myDecFact = myDecFact * 10;
        myPlaces = eval(myPlaces) + eval(1);
        myZeros = myZeros + "0";
    }
    onum = Math.round(num * myDecFact) / myDecFact;
    integer = Math.floor(onum);
    if (Math.ceil(onum) === integer) {
        decimal = myZeros;
    } else {
        decimal = Math.round((onum - integer) * myDecFact);
    }
    decimal = decimal.toString();
    if (decimal.length < places) {
        fillZeroes = places - decimal.length;
        for ( z = 0; z < fillZeroes; z++ ) {
            decimal = "0" + decimal;
        }
    }
    if (places > 0) {
        decimal = "." + decimal;
    }
    if (comma === 1) {
        integer=integer.toString();
        for ( x = integer.length; x > 0; x-- ) {
            tmpnum = tmpnum + integer.charAt(x - 1);
            y = y + 1;
            if (y === 3 && x > 1) {
                tmpnum = tmpnum + ",";
                y = 0;
            }
        }
        for ( x = tmpnum.length; x > 0; x-- ) {
            tmpinteger = tmpinteger + tmpnum.charAt(x - 1);
        }
        finNum = tmpinteger + "" + decimal;
    } else {
        finNum = integer + "" + decimal;
    }
    if(isNeg === 1) {
        finNum = "-" + finNum;
    }
    return finNum;
}

$(document).ready(function() {

    KMG.Slider.initialize();

    KMG.Forms.initialize();

    /**
     * ADA compliance for navigation dropdowns
     */
    document.querySelectorAll('nav .nav-item').forEach( (elem) => {
        // Open submenu on enter keypress
        elem.addEventListener('keydown', (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
                if (elem.parentElement.classList.contains('hover')) {
                    elem.parentElement.classList.remove('hover');
                    elem.setAttribute('aria-expanded', "false")
                } else {
                    elem.parentElement.classList.add('hover');
                    elem.setAttribute('aria-expanded', "true")
                }
            }
        });

        // Close submenu when it loses focus
        elem.addEventListener('focus', () => {
            if (!elem.classList.contains('hover')) {
                document.querySelectorAll('nav .nav-item').forEach( (sibling) => {
                    sibling.parentElement.classList.remove('hover');
                });
            }
        });
    });

	$(".mobile-dropdown").hide();

	$(".mobile-nav a.menu").click( () => {
	    let dropdown = $('.mobile-dropdown');
		if (dropdown.is(':hidden')){
			dropdown.slideDown(400);
		} else {
			dropdown.slideUp(200);
		}
	});

    /*$("ul[data-select-name='referral']").on("click", function(e) {
        var selection = $(e.target).data("option-value");
        if (selection === 'referral') {
            e.preventDefault();
            $("fieldset.referral").find("input[name='referral']").attr("placeholder", "Enter referral name").attr("type", "text").attr("value", "");
        } else {
            $("fieldset.referral").find("input[name='referral']").attr("placeholder", "").attr("type", "hidden");
        }
    });*/

});