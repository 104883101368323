'use strict';

import 'flexslider';

class Slider {

    initialize() {

        let testimonialSlider = $(".testimonial-slider");

        testimonialSlider.flexslider({
            animation: "slide",
            animationLoop: true,
            keyboard: false,
            directionNav: false,
            slideshow: true,
            controlNav: false
        });

    }
}

export { Slider }