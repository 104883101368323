import Inputmask from "inputmask";

class Forms {

    constructor() {
        this.leadForm = document.getElementById('lgen');
    }


    /**
     * Validate the current visible panel has values in required fields
     * @private
     */
    _validateStep(container) {

        let valid = true;

        /* Check all required selects */
        container.querySelectorAll('select[required]').forEach( (select) => {
            if (select.value === '') {
                valid = false;
            }
        });

        /* Check all required inputs */
        container.querySelectorAll('input').forEach( (input) => {
            if (!input.checkValidity()) {
                valid = false;
            }
        });

        let cont = container.querySelector('.button-group.continue'),
            submission = container.querySelector('.button-group.submission');

        if (valid) {
            if (cont) {
                cont.classList.remove('invisible');
            }
            if (submission) {
                submission.classList.remove('invisible');
            }
        } else {
            if (cont) {
                cont.classList.add('invisible');
            }
            if (submission) {
                submission.classList.add('invisible');
            }
        }

    }

    _maskInputs() {
        let elements = document.querySelectorAll('[data-mask]');
        elements.forEach( (input) => {
            let im = new Inputmask({"mask": input.dataset.mask});
            im.mask(input);
        });
    }


    _setFormHeight() {
        let height = 200;
        this.leadForm.querySelectorAll('.property-info').forEach ( (elem, index) => {
            if (elem.offsetHeight > height) {
                height = elem.offsetHeight;
            }
        });
        this.leadForm.querySelector('.form-slider').style.height = height + 'px';
    }

    /* Event listeners and handlers for the lead form */
    _initializeLeadForm() {

        /* Set a height based on tallest child element */
        this._setFormHeight();

        /* Shift elements off for slider */
        let slides = this.leadForm.querySelectorAll('form .property-info');
        slides.forEach( (slide, index) => {
            if (index > 0) {
                slide.classList.add('invisible');
            }
        });

        /* Setup custom selects */
        let selects = this.leadForm.getElementsByClassName('select-css');
        for (let select of selects) {
            select.addEventListener('change', () => {
                let container = select.parentNode.parentNode.parentNode;
                this._validateStep(container);

                // Custom show panels based on selection
                if (select.id === 'loan_purpose') {
                    let panel2 = this.leadForm.querySelector('.property-info-2'),
                        panel3 = this.leadForm.querySelector('.property-info-3'),
                        panel4 = this.leadForm.querySelector('.property-info-4'),
                        panel5 = this.leadForm.querySelector('.property-info-5'),
                        panel6 = this.leadForm.querySelector('.property-info-6'),
                        panel9 = this.leadForm.querySelector('.property-info-9'),
                        panel10 = this.leadForm.querySelector('.property-info-10'),
                        panelPP = this.leadForm.querySelector('.property-info-pp');

                    if (select.value === 'home_purchase') {
                        panel2.classList.remove('enabled');
                        panel2.querySelectorAll('select').forEach( (select) => {
                            select.removeAttribute('required');
                        });

                        panel3.classList.remove('enabled');
                        panel3.querySelectorAll('select').forEach( (select) => {
                            select.removeAttribute('required');
                        });
                        panel3.querySelectorAll('input').forEach( (input) => {
                            input.removeAttribute('required');
                        });

                        panel4.classList.remove('enabled');
                        panel4.querySelectorAll('select').forEach( (select) => {
                            select.removeAttribute('required');
                        });

                        panel5.classList.remove('enabled');
                        panel5.querySelectorAll('select').forEach( (select) => {
                            select.removeAttribute('required');
                        });

                        panel6.classList.remove('enabled');

                        panel9.classList.remove('enabled');
                        panel9.querySelectorAll('select').forEach( (select) => {
                            select.removeAttribute('required');
                        });

                        panel10.classList.remove('enabled');
                        panel10.querySelectorAll('input').forEach( (input) => {
                            input.removeAttribute('required')
                        });

                        panelPP.classList.add('enabled');
                        panelPP.querySelectorAll('select').forEach( (select) => {
                            select.setAttribute('required', 'required');
                        });

                    } else {
                        panel2.classList.add('enabled');
                        panel2.querySelectorAll('select').forEach( (select) => {
                            select.setAttribute('required', 'required');
                        });

                        panel3.classList.add('enabled');
                        panel3.querySelectorAll('select').forEach( (select) => {
                            select.setAttribute('required', 'required');
                        });
                        panel3.querySelectorAll('input').forEach( (input) => {
                            input.setAttribute('required', 'required');
                        });

                        panel4.classList.add('enabled');
                        panel4.querySelectorAll('select').forEach( (select) => {
                            select.setAttribute('required', 'required');
                        });

                        panel5.classList.add('enabled');
                        panel5.querySelectorAll('select').forEach( (select) => {
                            select.setAttribute('required', 'required');
                        });

                        panel6.classList.add('enabled');

                        panel9.classList.add('enabled');
                        panel9.querySelectorAll('select').forEach( (select) => {
                            select.setAttribute('required', 'required');
                        });

                        panel10.classList.add('enabled');
                        panel10.querySelectorAll('input').forEach( (input) => {
                            input.setAttribute('required', 'required');
                        });

                        panelPP.classList.remove('enabled');
                        panelPP.querySelectorAll('select').forEach( (select) => {
                            select.removeAttribute('required');
                        });
                    }
                }

                // Handle referral selection
                if (select.id === 'referral-select') {
                    let input = this.leadForm.querySelector('input[name="referral"]');
                    if (select.value === 'referral') {
                        input.value = '';
                        input.parentElement.classList.remove('hidden');
                    } else {
                        input.parentElement.classList.add('hidden');
                        input.value = select.value;
                    }
                    this._setFormHeight();
                }
            });
        }

        let inputs = this.leadForm.getElementsByTagName('input');
        for (let input of inputs) {
            input.addEventListener('keyup', (event) => {
                let container = event.target.parentNode.parentNode.parentNode;
                this._validateStep(container);
            });
        }

        if (slides) {
            this.leadForm.addEventListener('click', (event) => {

                let current = 0;

                /* Find current element */
                if (event.target.classList.contains('flex-prev') || event.target.classList.contains('flex-next')) {
                    for (let i = 0; i < slides.length; i++) {
                        if (slides[i].classList.contains('translate-x-0')) {
                            current = i;
                            break;
                        }
                    }
                }

                if (event.target.classList.contains('flex-prev')) {
                    event.preventDefault();

                    /* Find previous enabled element */
                    let prev = current;
                    for (let i = current - 1; i < slides.length; i--) {
                        if (slides[i].classList.contains('enabled')) {
                            prev = i;
                            slides[prev].classList.remove('invisible');
                            break;
                        }
                    }

                    /* Show prev/hide current */
                    slides[current].classList.add('translate-x-full');
                    slides[current].classList.remove('translate-x-0');

                    slides[prev].classList.remove('-translate-x-full');
                    slides[prev].classList.add('translate-x-0');

                    /* Focus first element */
                    slides[prev].querySelector('select, input').focus({preventScroll: true});

                    /* Hide the element that moved to prevent tabbing */
                    window.setTimeout( () => {
                        slides[current].classList.add('invisible');
                    }, 1000);
                }

                if (event.target.classList.contains('flex-next')) {
                    event.preventDefault();

                    /* Find next enabled element */
                    let next = current;
                    for (let i = current + 1; i < slides.length; i++) {
                        if (slides[i].classList.contains('enabled')) {
                            next = i;
                            slides[next].classList.remove('invisible');
                            break;
                        }
                    }

                    /* Show next/hide current */
                    slides[current].classList.add('-translate-x-full');
                    slides[current].classList.remove('translate-x-0');

                    slides[next].classList.remove('translate-x-full');
                    slides[next].classList.add('translate-x-0');

                    /* Focus first element */
                    slides[next].querySelector('select, input').focus({preventScroll: true});

                    /* Hide the element that moved to prevent tabbing */
                    window.setTimeout( () => {
                        slides[current].classList.add('invisible');
                    }, 1000);
                }

            });

            /* Stop enter key from trying to submit */
            this.leadForm.querySelectorAll('.flex-next, .flex-prev').forEach((elem) => {
                elem.addEventListener('keypress', (event) => {
                    if (event.keyCode === 13) {
                        event.preventDefault();
                    }
                })
            })
        }

        /* Advance on
        /* reCaptcha on submit */
        this.leadForm.addEventListener('submit', (event) => {
            let recaptchaResponse = this.leadForm.querySelector('#recaptcha-response');

            // If no recaptcha value we stop submission
            if (recaptchaResponse && recaptchaResponse.value === '') {

                event.preventDefault();

                grecaptcha.ready( () => {
                    grecaptcha.execute('6Lf1vakUAAAAAIZcsGqXxEFJdC8utF9JIShhmXQu', {action: 'lead_form'}).then( (token) => {
                        let recaptchaResponse = this.leadForm.querySelector('#recaptcha-response');
                        recaptchaResponse.value = token;

                        // Now submit with the recaptcha token
                        this.leadForm.submit();
                    });
                });
            }
        })

    }

    /* Set everything up */
    initialize() {

        // Setup masked inputs
        this._maskInputs();

        if (this.leadForm) {
            this._initializeLeadForm();
        }

        let contactForm = document.getElementById('contact');
        if (contactForm) {
            contactForm.addEventListener('submit', (event) => {
                let recaptchaResponse = document.getElementById('recaptcha-response');

                // If no recaptcha value we stop submission
                if (recaptchaResponse && recaptchaResponse.value === '') {

                    event.preventDefault();

                    grecaptcha.ready( () =>{
                        grecaptcha.execute('6Lf1vakUAAAAAIZcsGqXxEFJdC8utF9JIShhmXQu', { action: 'contact_form' }).then( (token) => {
                            let recaptchaResponse = contactForm.querySelector('#recaptcha-response');
                            recaptchaResponse.value = token;
                            // Now submit with the recaptcha token
                            contactForm.submit();
                        });
                    });
                }
            });
        }

        let branchApplication = document.getElementById('branchApplication');
        if (branchApplication) {
            contactForm.addEventListener('submit', (event) => {
                let recaptchaResponse = document.getElementById('recaptcha-response');

                // If no recaptcha value we stop submission
                if (recaptchaResponse && recaptchaResponse.value === '') {

                    event.preventDefault();

                    grecaptcha.ready( () =>{
                        grecaptcha.execute('6Lf1vakUAAAAAIZcsGqXxEFJdC8utF9JIShhmXQu', { action: 'branch_application' }).then( (token) => {
                            let recaptchaResponse = branchApplication.querySelector('#recaptcha-response');
                            recaptchaResponse.value = token;
                            // Now submit with the recaptcha token
                            branchApplication.submit();
                        });
                    });
                }
            });
        }

    }
}

export { Forms }